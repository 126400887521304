<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { boolean, object, string } from 'zod';
    import { toTypedSchema } from '@vee-validate/zod';
    import { useField, useForm } from 'vee-validate';
    import FormField from '@/components/form-components/FormField.vue';
    import { hasValidationError } from '@/util/hasValidationError';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import Button from 'primevue/button';
    import Password from 'primevue/password';
    import Message from 'primevue/message';
    import { computed, ref } from 'vue';
    import ErrorTag from '@/components/ErrorTag.vue';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';
    import { getLogger } from '@/logger/logger';
    import { passwordRequirements } from '@/common/validation/password';
    import PasswordRequirements from '@/common/components/PasswordRequirements.vue';
    import CheckboxField from '@/components/form-components/CheckboxField.vue';
    import { useMarketQuery } from '@/composables/market';
    import { jwtDecode } from 'jwt-decode';
    import { ConsentFlags } from '@containex/portal-business-logic';

    const props = defineProps<{
        email: string;
        token: string;
    }>();

    const decodedEmail = computed(() => decodeURIComponent(props.email));

    const { t } = useI18n();
    const logger = getLogger('PasswordActivateView');
    const { market } = useMarketQuery();

    const marketCode = computed(() => market?.value?.code.toLowerCase() ?? 'at');
    const privacyPolicyUrl = computed(() => `https://www.containex.com/${marketCode.value}/de/datenschutz`);
    const agbUrl = computed(() => `https://www.containex.com/${marketCode.value}/de/nutzungsbedingungen`);

    const { lengthCheck, numberCheck, letterCheck, validatorFn } = passwordRequirements();

    const showError = ref(false);

    const schema = object({
        password: string()
            .trim()
            .refine(validatorFn, {
                message: t('FORM.PASSWORD_VALIDATION.ERROR'),
            }),
        passwordConfirm: string().refine(areSamePassword, {
            message: t('FORM.PASSWORD_CONFIRM_VALIDATION_TEXT'),
        }),
        acceptDsgvo: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.DSGVO_TEXT_VALIDATION_TEXT'),
            }),
        acceptTermsOfService: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.TOS_TEXT_VALIDATION_TEXT'),
            }),
        acceptGeneralTerms: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.AGB_TEXT_VALIDATION_TEXT'),
            }),
        acceptDataForwarding: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.DATA_FORWARDING_VALIDATION'),
            }),
        acceptRetailerForwarding: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.RETAILER_FORWARDING_VALIDATION'),
            }),
    });

    const { handleSubmit, errors } = useForm({
        validationSchema: toTypedSchema(schema),
    });
    const { value: password } = useField<string>('password');
    const { value: passwordConfirm } = useField<string>('passwordConfirm');
    const { value: acceptDsgvo } = useField<boolean>('acceptDsgvo');
    const { value: acceptGeneralTerms } = useField<boolean>('acceptGeneralTerms');
    const { value: acceptTermsOfService } = useField<boolean>('acceptTermsOfService');
    const { value: acceptDataForwarding } = useField<boolean>('acceptDataForwarding');
    const { value: acceptRetailerForwarding } = useField<boolean>('acceptRetailerForwarding');

    const onSubmit = handleSubmit(async (values) => {
        showError.value = false;
        const authenticationAction = useAuthenticationAction();
        try {
            const consentFlags = createConsentFlags(values);
            await authenticationAction.activateCustomer(decodedEmail.value, values.password, props.token, consentFlags);
            await router.push({ name: Navigation.SignupActivateSuccess });
        } catch (error) {
            logger.error(error, 'Setting password failed');
            showError.value = true;
        }
    });

    const { isUserLoggedIn } = useAuthenticationQuery();

    function areSamePassword(value: string): boolean {
        return password.value === value;
    }

    function createConsentFlags(values: {
        acceptDsgvo: boolean;
        acceptTermsOfService: boolean;
        acceptGeneralTerms: boolean;
        acceptDataForwarding: boolean;
        acceptRetailerForwarding: boolean;
    }): ConsentFlags {
        const consentFlags = new ConsentFlags();
        consentFlags.general_terms_accepted = values.acceptGeneralTerms;
        consentFlags.dsgvo_accepted = values.acceptDsgvo;
        consentFlags.data_forwarding_accepted = values.acceptDataForwarding;
        consentFlags.terms_of_service_accepted = values.acceptTermsOfService;
        consentFlags.retailer_forwarding_accepted = values.acceptRetailerForwarding;
        return consentFlags;
    }

    const isTokenExpired = computed(() => {
        try {
            const decoded = jwtDecode(props.token);
            if (decoded?.exp == null) {
                return true;
            }

            if (Date.now() >= decoded.exp * 1000) {
                return true;
            }
        } catch {
            // something went wrong with the token, we assume it's invalid at this point
            return true;
        }

        return false;
    });
</script>

<template>
    <div v-if="!isUserLoggedIn" class="flex-container">
        <Message v-if="isTokenExpired" icon="pi pi-exclamation-triangle" severity="warn" :closable="false">
            <div>
                {{ t('LOGIN.ACTIVATE_PASSWORD.TOKEN_EXPIRED_DESCRIPTION') }}
            </div>
            <router-link class="text-link" :to="{ name: Navigation.Signup }">{{
                t('LOGIN.ACTIVATE_PASSWORD.GO_TO_SIGNUP')
            }}</router-link>
        </Message>
        <template v-if="!isTokenExpired">
            <h2>{{ t('LOGIN.ACTIVATE_PASSWORD.TITLE') }}</h2>
            <p class="text">{{ t('LOGIN.ACTIVATE_PASSWORD.DESCRIPTION', { email: decodedEmail }) }}</p>
            <form class="form" @submit.prevent="onSubmit">
                <div>
                    <FormField :label="t('FORM.PASSWORD')" :error-message="errors.password">
                        <Password
                            v-model="password"
                            input-id="password"
                            class="full-width"
                            :input-class="showError ? 'error-border' : 'full-width'"
                            :feedback="false"
                            :invalid="hasValidationError(errors.password)"
                        />
                    </FormField>
                    <PasswordRequirements
                        :letter-check="letterCheck"
                        :number-check="numberCheck"
                        :length-check="lengthCheck"
                    ></PasswordRequirements>
                    <FormField :label="t('FORM.PASSWORD_CONFIRM')" :error-message="errors.passwordConfirm">
                        <Password
                            v-model="passwordConfirm"
                            input-id="passwordConfirm"
                            class="full-width"
                            :input-class="showError ? 'error-border' : 'full-width'"
                            :feedback="false"
                            :invalid="hasValidationError(errors.passwordConfirm)"
                        />
                    </FormField>
                    <CheckboxField
                        v-model="acceptDsgvo"
                        class="checkbox"
                        :binary="true"
                        :invalid="hasValidationError(errors.acceptDsgvo)"
                        :error-message="errors.acceptDsgvo"
                    >
                        {{ t('FORM.DSGVO_TEXT.PRE') }}
                        <a :href="privacyPolicyUrl" class="privacy-policy-link" target="_blank">
                            {{ t('FORM.DSGVO_TEXT.PRIVACY_POLICY') }}
                        </a>
                        {{ t('FORM.DSGVO_TEXT.POST') }}
                    </CheckboxField>
                    <CheckboxField
                        v-model="acceptGeneralTerms"
                        class="checkbox"
                        :binary="true"
                        :invalid="hasValidationError(errors.acceptGeneralTerms)"
                        :error-message="errors.acceptGeneralTerms"
                    >
                        {{ t('FORM.AGB_TEXT.PRE') }}
                        <a :href="agbUrl" class="privacy-policy-link" target="_blank">
                            {{ t('FORM.AGB_TEXT.AGB') }}
                        </a>
                        {{ t('FORM.AGB_TEXT.POST') }}
                    </CheckboxField>
                    <CheckboxField
                        v-model="acceptTermsOfService"
                        class="checkbox"
                        :binary="true"
                        :invalid="hasValidationError(errors.acceptTermsOfService)"
                        :error-message="errors.acceptTermsOfService"
                    >
                        {{ t('FORM.TOS_TEXT') }}
                    </CheckboxField>
                    <CheckboxField
                        v-model="acceptDataForwarding"
                        class="checkbox"
                        :binary="true"
                        :invalid="hasValidationError(errors.acceptDataForwarding)"
                        :error-message="errors.acceptDataForwarding"
                    >
                        {{ t('FORM.DATA_FORWARDING') }}
                    </CheckboxField>
                    <CheckboxField
                        v-model="acceptRetailerForwarding"
                        class="checkbox"
                        :binary="true"
                        :invalid="hasValidationError(errors.acceptRetailerForwarding)"
                        :error-message="errors.acceptRetailerForwarding"
                    >
                        {{ t('FORM.RETAILER_FORWARDING') }}
                    </CheckboxField>
                </div>
                <ErrorTag v-if="showError" :message="t('LOGIN.ACTIVATE_PASSWORD.ERROR_SUMMARY')" />
                <div>
                    <Button type="submit" :label="t('LOGIN.ACTIVATE_PASSWORD.SAVE_BUTTON')" />
                </div>
            </form>
        </template>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkbox {
        margin-top: main.$spacing-4;
    }

    .flex-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        background: main.$color-white;
        padding: main.$spacing-6;
        max-width: 448px;

        :deep(.error-border) {
            border-color: main.$color-error-red;
        }
    }

    .text {
        margin-bottom: 0;
    }

    .form {
        background: main.$color-white;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: main.$spacing-6;
    }
</style>
